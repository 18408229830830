import React from 'react';

import ServicesLayout from '../../components/ServicesLayout';
import FSAImage from '../../images/image_FSA.jpg';

const Enrollment = () => (
  <ServicesLayout title="Flexible Spending Accounts" bannerTitle="Flexible Spending Accounts" bannerCopy="Donec facilisis tincidunt orci non vestibulum. Phasellus pulvinar ante eu vulputate dapibus. Quisque sodales metus at urna dictum consectetur.">
    <h2 className="inner-page__heading">Flexible Spending Accounts (FSA)</h2>
    <p className="inner-page__lead">Simple, intuitive website & FSA debit cards let participants easily track, manage & access FSA funds.</p>
    <img className="inner-page__hero" src={FSAImage} alt="Woman at laptop with flex spending card in hand" />

    <p>Every plan participant wants to know they can use their FSA dollars when and where they’re needed. Tri-Star processes online claims in 2 hours and offers stored-value debit cards for even faster, direct access to FSA funds.</p>

    <h3>FSA Administration</h3>
    <p>Tri-Star offers complete FSA administrative services through intuitive websites for both participants and employers that make managing FSAs simple and stress-free.</p>

    <h3>Participant Highlights</h3>
    <p>Tri-Star makes it easy for participants to self-manage their FSA accounts and access their funds quickly when needed</p>
    <ul>
      <li><strong>24/7 Self-Service Access</strong> lets participants check balances, submit expenses and track claim approvals</li>
      <li><strong>Secure, Easy-to-Use Website</strong> can be accessed from any computer, tablet or smartphone</li>
      <li><strong>Two Hour Claim Processing</strong> when submitted online</li>
      <li><strong><a href="#debit-card">Optional Debit Card</a></strong> with stored FSA funds for direct payment of qualified providers</li>
      <li><strong>Live Customer Service</strong> ready to educate, explain and support</li>
    </ul>


    <h3>Employer Highlights</h3>
    <p>Tri-Star provides the management expertise, customer service and participant communication support at every stage to create the best possible plan for your organization’s unique needs</p>
    <ul>
      <li><strong>Customized Plan Design</strong> to ensure benefit plans that meet the needs of employers and participants</li>
      <li><strong>Simplified Enrollment</strong> and clear communication to participants reduces HR involvement</li>
      <li><strong>Fast Reimbursement</strong> with two-hour claim processing or <a href="#debit-card">stored value debit cards</a></li>
      <li><strong>Reporting</strong> in customized formats, with role-based HR access</li>
      <li><strong>Compliance Assurance</strong> for documentation and expense qualification</li>
    </ul>

    <h3 id="debit-card">FSA Stored Value Debit Cards</h3>
    <p>To make it even faster and easier for participants to access their FSA dollars, Tri-Star also offers FSA stored Value Debit Cards as a way for employees to pay qualifying medical expenses directly, without paying out of pocket and waiting for reimbursement.  This can also significantly reduce paperwork filing for the employee.</p>
    <p>The cards are designed to ensure that only qualified expenses, backed by money set aside in an FSA, can be paid with the card.</p>
    <ul>
      <li>Cards will work only at physicians, dentists, hospitals, clinics or other medical providers, or merchants who sell prescription drugs or over-the-counter medical supplies</li>
      <li>Card balances are synced with the participant’s account balance in Tri-Star’s system, and will be restricted to current available funds</li>
      <li>Online claims are also synced with the card, preventing over-reimbursement of of the FSA balance</li>
      <li>Participants will receive email notification if further documentation is required, and the card will be inactivated if documents are not received or expenses are not qualified</li>
    </ul>
  </ServicesLayout>
);

export default Enrollment
